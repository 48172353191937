.element{
    margin: 1%;
    border: 0;
    padding: 2%;
    text-decoration: none;
    font-size: 1.5rem;
    text-align: left;
    box-shadow: 5px 5px 5px 5px rgb(89, 51, 179);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    color: black;
    text-decoration-line: none;
}

Link{
    text-decoration: none;
}

.element:hover{
    border-style: groove;
    border: 5px;
    border-color: red;
    box-shadow: 5px 5px 5px 5px rgb(255, 0, 0);
}

.element:active{
    box-shadow: 3px 3px 3px 3px rgb(0, 255, 0);
}