* {box-sizing: border-box;}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.audio{
  padding-left: 10px ;
  height: 47px;
  float: left;
  display: block;
  color: rgb(255, 255, 255);
  text-align: center;
  background-color: #F1F4F7;
}

.topnav {
  overflow: hidden;
  background-color: #6d2dc2;
  color: white;
}

.topnav a {
  float: left;
  display: block;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: rgb(62, 56, 145);
  color: rgb(255, 255, 255);
}

.topnav a.active {
  background-color: #009eb3;
  color: white;
}

.topnav .search-container {
  float: right;
}

.topnav input[type=text] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .button{
  padding: 6px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .button:hover {
  background: #ccc;
}

@media screen and (max-width: 800px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a, .topnav input[type=text], .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type=text] {
    border: 1px solid #ccc;  
  }
}