* {
  box-sizing: border-box;
}

iframe {
    width: 100%;
    height: 90vh;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
  background-color: #f1f4f7;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.play{
  padding: 2px;
  background-color: skyblue;
  color: rgb(0, 0, 0);
  height: 50px;
  cursor: pointer;
  width: 80px;
  box-shadow: 1px 1px 1px 1px rgb(0, 0, 0);
}

.play:hover{
  padding: 2px;
  background-color: skyblue;
  color: rgb(255, 0, 0);
  box-shadow: 1px 1px 1px 1px rgb(238, 113, 113);
}

.play:active{
  padding: 2px;
  background-color: rgb(46, 120, 150);
  color: rgb(0, 255, 0);
  box-shadow: 1px 1px 1px 1px rgb(76, 233, 110);
}

.music1 img {
  width: 100%;
  height: auto;
}

.music2 img {
  width: 100%;
  height: auto;
}

.m-a {
  position: relative;
  left: 25%;
}

@media(max-width:800px){
  .m-a{
    left: 0;
  }
}

.slider {
  -webkit-appearance: none;
  width: 90%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #a200ff;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #0082b6;
  cursor: pointer;
}

img{
    width: 100%; 
    height: auto;
}